import { LookupResponse } from '../../../models/lookup-response.interface'

const sampleLookupData = {
  fullname: 'dailynews.com',
  status: 'unavailable',
  total_length: '9',
  age: '24 Years 11 months 1 days',
  first_discovery: '25 years 1 months 12 days 5 hours',
  vowels: '4',
  consonants: '6',
  syllable_count: '3',
  spelling: 'Very Easy',
  pronounceability: '10',
  complexity: 'Low',
  readability: '10',
  memorability: '8.5',
  registerd_tlds:'148',
  simplicity: '9',
  brandability: '8.5',
  brand_potential: '8',
  target_audience_appeal: '9',
  type_in_traffic: 'High',
  radio_test: 'Passes',
  semantic_analysis: 'Clearly Coherent',
  monthly_search_volume: '201000',
  cpc: '0.13',
  paid_competition: '0.03',
  total_backlinks: '9544',
  domain_authority: '84',
  page_authority: '59',
  citation_flow: '62',
  trust_flow: '62',
  referring_domains: '24083',
  global_rank: '10638',
  traffic: '217016',
  domain_reputation: '30',
  domain_threat_level: 'favorable',
}

interface LookupChild {
  name: string
  value?: string
  label?: string
}

export interface LookupData extends LookupChild {
  children?: LookupChild[]
}

export const constructLookupData = async (
  response?: LookupResponse
): Promise<LookupData[]> => {
  let data = response?.data?.[0] || response?.data

  if (!data) {
    data = sampleLookupData
  }

  return [
    {
      name: 'Domain',
      value: data.fullname,
      label:
        'Domain name is the name of the website. It is the address of the website on the internet. It is the unique name that identifies the website. It is the name that is used to access the website.',
    },
    {
      name: 'Availability',
      value: data.status,
      label:
        'Domain availability is the status of the domain name. It can be available or unable to register.',
    },
    {
      name: 'Length',
      value: data.total_length,
      label:
        'Length of the domain name is the number of characters in the domain name. The shorter the domain name, the better it is.',
    },
    {
      name: 'Age',
      value: data.age,
      label:
        'Age of the domain name is the time since the domain name was registered. The older, the better it is.',
    },
    {
      name: 'First Discovery',
      value: data.first_discovery,
      label:
        'First discovery of the domain name is the time since the domain name was first discovered on the wayback machine. The older,the better it is.',
    },
    {
      name: 'Vowels',
      value: data.vowels,
      label:
        'The number of vowels in the domain name.',
    },
    {
      name: 'Consonants',
      value: data.consonants,
      label:
        'The number of consonants in the domain name.',
    },
    {
      name: 'Syllable Count',
      value: data.syllable_count,
      label:
        'Syllable count of the domain name is the number of syllables in the domain name.',
    },
    {
      name: 'Spelling',
      value: data.spelling,
      label:
        'How easy the domain name is to spell. The easier the domain name is to spell, the better it is.',
    },
    {
      name: 'Pronounceability',
      value: data.pronounceability,
      label:
        'Pronounceability of the domain name is the ease with which the domain name can be pronounced. The easier the domain name is to pronounce, the better it is.',
    },
    {
      name: 'Complexity',
      value: data.complexity,
      label:
        'How complex is your domain name? The lower the complexity, the better it is.',
    },
    {
      name: 'Readability',
      value: data.readability,
      label:
        'Readability of the domain name is the ease with which the domain name can be read. The easier the domain name is to read, the better it is.',
    },
    {
      name: 'Memorability',
      value: data.memorability,
      label:
        'How easy is it to remember the domain name? The easier the domain name is to remember, the better it is.',
    },
    {
      name: 'Simplicity',
      value: data.simplicity,
      label:
        'Simplicity refers to how easy it is to understand, remember, and use the domain name.',
    },
    {
      name: 'Brandability',
      value: data.brandability,
      label:'Brandability in the context of domain names refers to how well the domain name can be used to build a brand.',
    },
    {
      name: 'Brand Potential',
      value: data.brand_potential,
      label:'Does your domain offer great brand potential? ',
    },
    {
      name: 'Target Audience Appeal',
      value: data.target_audience_appeal,
      label:'How well the domain name appeals to the target audience?',
    },
    {
      name: 'Type-in Traffic',
      value: data.type_in_traffic,
      label:'Type in traffic in the context of domain names refers to the amount of traffic a domain receives from users who type the domain name directly into their browser address bar',
    },
    {
      name: 'Radio Test',
      value: data.radio_test,
      label:'Radio test in the context of domain names refers to how well the domain name can be understood when spoken aloud',
    },
    {
      name: 'Semantic Analysis',
      value: data.semantic_analysis,
      label:'Categorize domain names based on their semantic analysis and word positioning',
    },
    {
      name: 'Registerd TLDs',
      value: data.registerd_tlds,
      label:'Number of TLDs registered for the domain name',
    },
    // {
    //   name: 'Versatility',
    //   value: data.versatility,
    //   label:'Refers to the ability of the domain to adapt and be effective across various applications and marketing channels',
    // },
    {
      name: 'Search Volume',
      value: data.monthly_search_volume,
      label:
        'Monthly search volume of the domain name is the number of times the domain name was searched for in the last month.',
    },
    {
      name: 'CPC',
      value: data.cpc,
      label:
        'CPC of the domain name is the cost per click of the domain name. The higher the CPC, the better it is.',
    },
    {
      name: 'Competition',
      value: data.paid_competition,
      label:
        'Competition of the domain name is the number of advertisers competing for the domain name. The lower the competition, the better it is.',
    },
    {
      name: 'Backlinks',
      value: data.total_backlinks,
      label:
        'Backlinks of the domain name is the number of websites that link to the domain name. The more, the better it is.',
    },
    {
      name: 'Domain Authority',
      value: data.domain_authority,
      label:
        'Domain Authority of the domain name. The higher the score, the better it is.',
    },
    {
      name: 'Page Authority',
      value: data.page_authority,
      label:
        'Page Authority of the domaina name. The higher the score, the better it is.',
    },
    {
      name: 'Citiation Flow',
      value: data.citation_flow,
      label:
        'Citation Flow is a score which reflects the quantity of links that point to any given website. The higher the score, the better it is.',
    },
    {
      name: 'Trust Flow',
      value: data.trust_flow,
      label:
        'Trust Flow is a metric that measures the quality and trustworthiness of the links pointing to a website. It is based on the idea that links from reputable and authoritative websites are more valuable. A higher Trust Flow score indicates a link profile consisting of high-quality, trustworthy backlinks.',
    },
    {
      name: 'Referring Domains',
      value: data.referring_domains,
      label:
        'Referring Domains of the domain name is the number of websites that link to the domain name. The more, the better it is.',
    },
    {
      name: 'Global Rank',
      value: data.global_rank,
      label:
        'Global Rank is a score of the domain name. The higher the score, the better it is.',
    },
    {
      name: 'Domain Traffic',
      value: data.traffic,
      label:
        'Number of users expected to visit the website during the following month.',
    },
    {
      name: 'Domain Reputation',
      value: data.domain_reputation,
      label:
        'Domain Reputation of the domain name is the score of the domain name.',
    },
    {
      name: 'Threat Level',
      value: data.domain_threat_level,
      label:
        ' Threat Level of the domain name is the score of the domain name. The lower the score, the better it is.',
    },
    {
      name: 'Similar Sales',
      children: data?.similar_sales?.map((sale) => {
        return {
          name: sale.fullname,
          value: `$${sale.price}`,
          label: ' Sold domains which are similar to this domain name.',
        }
      }),
    },
  ]
}
